import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
// export const baseUrl = "https://k-book.icrcompany.net/api/v1/";
// export const fileUrl = "https://k-book.icrcompany.net/storage/";
// export const userUrl = "https://kr-book.icrcompany.net/#/auth/admin/sign-in/";
export const baseUrl = "https://backend.qiranbook.com/api/v1/";
export const fileUrl = "https://backend.qiranbook.com/storage/";
// export const userUrl = "https://qiranbook.com/#/auth/admin/sign-in/";
// export const baseUrl = "http://192.168.1.10:8001/api/v1/";
// export const fileUrl = "http://192.168.1.10:8001/storage/";
export const userUrl = "http://qiranbook.com/#/auth/admin/sign-in/";
export const gender = sessionStorage.getItem("gender");

//!=============================================================> post data login
export const useLOGIN = (initialState) => {
  const [formData, setFormData] = useState(initialState); // data
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { setMessage, setSuccessMessage } = useContextHook();
  const navigate = useNavigate();
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (url) => {
    setLoading(true);
    setError("");
    const formDataToSend = new FormData();
    for (const [key, value] of Object.entries(formData || {})) {
      formDataToSend.append(key, value);
    }
    axios
      .post(`${baseUrl}${url}`, formDataToSend)
      .then((req) => {
        setLoading(false);
        setError("");
        setSuccessMessage(req);
        localStorage.setItem("token", req?.data?.data?.token);
        navigate("/");
      })
      .catch((e) => {
        setError(e?.response?.data);
        setMessage(e?.response?.data?.errors);
        setLoading(false);
      });
  };
  return {
    formData,
    handleChange,
    loading,
    error,
    handleSubmit,
    setFormData,
  };
};
// !======================================================================================
export const usePOST = (initialState) => {
  const [formData, setFormData] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [viewFile, setViewFile] = useState("");
  const [viewImages, setViewImages] = useState([]);
  const [images, setImages] = useState({});
  const [checkArray, setCheckArray] = useState({});
  const [counter, setCounter] = useState(0);
  const { setMessage, setSuccessMessage } = useContextHook();

  const navigate = useNavigate();
  //=====================================================> function change data all
  const handleChangeInput = (event) => {
    const { name, value, type, files, checked } = event.target;
    const newValue =
      type === "file" ? files[0] : type === "checkbox" ? checked : value;
    type === "file" && setViewFile(URL.createObjectURL(newValue));
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: newValue === true ? 1 : newValue === false ? 0 : newValue,
    }));
  };
  const handleChangeSelect = (selected, active) => {
    setFormData({
      ...formData,
      [active.name]: Array.isArray(selected)
        ? selected.map((e) => e.value)
        : selected.value,
    });
  };

  //=====================================================> function change array images
  const handleChangeArrayImages = (event) => {
    const files = event.target.files;
    const newFilesObject = {};

    // Generate unique keys for new files
    const currentImagesCount = Object.keys(images).length;
    for (let i = 0; i < files.length; i++) {
      newFilesObject[`images[${currentImagesCount + i}]`] = files[i];
    }

    setImages((prevImages) => ({
      ...prevImages,
      ...newFilesObject,
    }));

    const urlImages = [];
    for (let i = 0; i < files.length; i++) {
      if (!files[i]) continue;
      urlImages.push(URL.createObjectURL(files[i]));
    }

    setViewImages((prev) => [...prev, ...urlImages]);
    event.target.value = "";

    //   const { files, name } = event.target;
    //   setImages({ ...images, [name]: [files[0]] });
    //   if (images[name]) {
    //     setImages({
    //       ...images,
    //       [name]: [...images[name], files[0]],
    //     });
    //   }
    //   setViewImages({ ...viewImages, [name]: [URL.createObjectURL(files[0])] });
    //   if (images[name]) {
    //     setViewImages({
    //       ...viewImages,
    //       [name]: [...viewImages[name], URL.createObjectURL(files[0])],
    //     });
    //   }
  };
  //=====================================================> function change array checkbox
  const handleCheckedArray = (e) => {
    const { value, checked, name } = e.target;
    if (checked) {
      setCheckArray({ ...checkArray, [name]: [value] });
      if (checkArray[name]) {
        setCheckArray({
          ...checkArray,
          [name]: [...checkArray[name], value],
        });
      }
    } else {
      if (checkArray[name]) {
        setCheckArray({
          ...checkArray,
          [name]: checkArray[name].filter((p) => p !== value),
        });
      }
    }
  };

  //=====================================================> post data
  const handleSubmit = async (url, route, noRoute, reload, data) => {
    setLoading(true);
    setError("");
    const formDataToSend = new FormData();
    if (data) {
      for (const [key, value] of Object.entries(data)) {
        if (value || value == 0) {
          formDataToSend.append(key, value);
        }
      }
    }
    else {
      for (const [key, value] of Object.entries(formData)) {
        if (value || value == 0) {
          formDataToSend.append(key, value);
        }
      }
    }

    for (const [key, value] of Object.entries(checkArray)) {
      for (let i = 0; i < value.length; i++) {
        formDataToSend.append(key, value[i]);
      }
    }
    for (const [key, value] of Object.entries(images)) {
      formDataToSend.append(key, value);
    }
    axios
      .post(`${baseUrl}${url}`, formDataToSend, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((req) => {
        setLoading(false);
        setMessage([]);
        setSuccessMessage(req.status);
        setError("");
        route
          ? navigate(route)
          : noRoute
            ? console.log(req.response.data)
            : reload
              ? window.location.reload()
              : window.history.go(-1);
      })
      .catch((e) => {
        setError(e.response?.data?.errors);
        setMessage(e?.response?.data?.errors);
        setLoading(false);
      });
  };
  return {
    formData,
    setFormData,
    viewFile, //  لعرض الصورة او فيديو .....
    setViewFile, // ((local)) لحذف الصورة
    viewImages, // لعرض الصور
    setViewImages, // ((local)) لحذف الصور
    images, //api  لأرسال الصور الى
    setImages, //api  لحذف الصور الى
    error,
    setError,
    loading,
    handleChangeSelect,
    setLoading,
    handleChangeInput,
    handleCheckedArray,
    handleChangeArrayImages,
    handleSubmit,
    checkArray,
    setCheckArray,
    counter,
    setCounter,
  };
};

//!=============================================================>start fetch data
export const useFETCH = (url, urlDelete, name) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isErroe, setIsError] = useState("");
  const [successfulDelete, setSuccessfulDelete] = useState(0);
  const { pathname } = useLocation();
  const { setMessage, setSuccessMessage } = useContextHook();

  const navigate = useNavigate();
  useEffect(() => {
    if (url) {
      setIsLoading(true);
      axios
        .get(`${baseUrl}${url}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((req) => {
          if (req) {
            setData(req);
            setIsLoading(false);
            setMessage([]);
            setIsError("");
          }
        })
        .catch((e) => {
          setIsLoading(false);

          if (e.request.status === 401) {
            navigate("/login");
            localStorage.clear();
            return;
          }
          setMessage(["الشبكة ضعيفة لا يوجد إتصال بالانترنت"]);
          setIsError("خطأ في جلب البيانات");
          if (e.request.status === 403) {
            navigate("/403");
          }
          if (e.request.status === 500) {
            navigate("/500");
          }
        });
    }
  }, [url, successfulDelete]);

  //?=============================================================> deleteItem
  const deleteItem = async (e, endpoint) => {
    setIsLoading(true);
    axios
      .delete(
        `${baseUrl}${urlDelete || url}/${endpoint ? endpoint : name ? e.name : e.id
        }`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((req) => {
        setIsLoading(true);
        setIsError("تم الحذف  بنجاح");
        setMessage([]);
        setSuccessMessage(req?.data);
        navigate(pathname);
        setSuccessfulDelete(successfulDelete + 1);
      })
      .catch((e) => {
        setIsLoading(false);
        setMessage([e?.response?.data?.message ?? "حدث مشكلة في عملية الحذف"]);
        setIsError(" خطأ في الحذف  حاول مرة آخرى");
      });
  };
  return { data, isLoading, isErroe, deleteItem };
};
//=============================================================> end fetch data
const navigate = useNavigate;

export const logout = (url) => {
  axios
    .post(
      `${baseUrl}${url}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    )
    .then((req) => {
      localStorage.removeItem("token");
      window.location.reload();
      navigate("/login");
    })
    .catch((e) => { });
};
//?=============================================================> close items
export const useClose = () => {
  const [open, setOpen] = useState(false);
  const mouse = useRef();
  useEffect(() => {
    const handler = (e) => {
      if (mouse.current) {
        if (!mouse.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.addEventListener("mousedown", handler);
    };
  }, []);
  return { open, setOpen, mouse };
};
//!=============================================================> pagination or filter or search
export const useFilter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const handleParamsClick = (name, value) => {
    if (Array.isArray(value)) {
      if (value.length > 0) searchParams.set(name, value);
      else searchParams.delete(name);
    } else if (value) {
      searchParams.set(name, value);
    } else searchParams.delete(name);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
  };
  const handleParamsDelete = (name) => {
    searchParams.delete(name);
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    navigate(newUrl);
  };
  const handleParamsDeleteAll = (name) => {
    const newUrl = `${location.pathname}`;
    navigate(newUrl);
  };
  const handlePageClick = (event) => {
    handleParamsClick("page", event.selected + 1);
  };
  return {
    searchParams,
    handlePageClick,
    handleParamsClick,
    handleParamsDelete,
    handleParamsDeleteAll,
  };
};
//!=============================================================> عدم دخول من غير تسجيل دخول
export const RequireAuth = () => {
  if (!window.localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};
//! =============================================================>عدم فتح صفحة تسجيل دخول اذا مسجل مسيقا
export const RequireLogin = () => {
  if (window.localStorage.getItem("token")) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

//!=============================================================> تكبير الصورة
export const clickZoomInImage = (event) => {
  event.target.requestFullscreen();
};

export const useContactUs = (url) => {
  const [loading, setLoading] = useState(false);
  const updateContactUs = (info, info_value) => {
    setLoading(true);
    axios
      .post(
        `${baseUrl}${url}`,
        {
          info: info,
          // [en_info_value ? "en_info_value" : "info_value"]: info_value,
          info_value: info_value,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((req) => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };
  return { updateContactUs, loading };
};
