import { useLocation, useNavigate } from "react-router-dom";
import img1 from "../../Svg/Frame 20.svg";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs/useMyAPI";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useContextTranslate } from "../../Translate/ContextTranslate";
import { Add, MainTitle, Statistics, Table } from "../../components";
import UsernameSearch from "../../components/Popup/UsernameSearch";
import PopUpAdvancedSearch from "./PopUpAdvancedSearch";
import { useEffect } from "react";
import DownloadFile from "../../components/DownloadFile";
import { toast } from "sonner";

function Users() {
  const { data: user } = useFETCH("admin/profile");
  const isAdmin = user?.data?.data?.role?.name === "super_admin";
  const { content } = useContextTranslate();
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/users${search}`,
    `admin/users`
  );

  const {
    formData,
    handleSubmit,
    setFormData,
    loading,
    handleChangeInput
  } = usePOST({ is_active: 0 }, () => {
    // toast.success("تمت الإضافة بنجاح");
    setFormData({});
  },
    (error) => {
      toast.error(`فشل الإضافة. ${error.response?.data.message}`);
    });

  const handleClick = async (e) => {
    e.preventDefault();
    handleChangeInput(e)
    const file = e.target.files?.[0];
    if (!file) {
      toast.error("يرجى اختيار ملف Excel.");
      return;
    }

    try {
      const updatedObject = { ...formData };
      updatedObject[`excel_file`] = file;

      await handleSubmit(`admin/users/import-excel`, false, true, false, updatedObject);
      toast.success("تم تحميل الملف بنجاح.");
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("حدث خطأ أثناء تحميل الملف.");
    } finally {
      e.target.value = null;
    }
  };

  let dataOld = data?.data.data;


  useEffect(() => {
    navigate(pathname + search);
  }, [search]);

  const dataAll = data?.data.data.data?.map((e) => {
    return {
      id: e?.login_details?.id,
      images:
        e?.images.length > 0
          ? fileUrl + e?.images[e?.images?.length - 1].image
          : "http",
      username: e.login_details?.username,
      email: e.login_details?.email,
      phone_number: e.secret_data?.phone_number,
      gender: e.gender === "FEMALE" ? "أنثى" : "ذكر",
      country: e.nationality_residence?.country?.name,
      counter_reports_count: e.counter_reports_count
        ? e.counter_reports_count
        : "0",
    };
  });

  return (
    <>
      <MainTitle title={content.Users} />
      <Container>
        <Row justify="between" className="items-center w-full">
          <Col lg={5} md={10} sm={6}>
            <div className="flex flex-row w-full justify-between items-center">
              <Statistics
                img={img1}
                name={content.UsersNumber}
                number={data?.data.data.total}
              />
              <DownloadFile
                fileName="exported-schedule.xlsx"
                url={`admin/users/export-excel/file${search}`}
                title="استخراج ملف excel"
                fileType="excel"
                color="bg-green-500"
              />

              <label
                className={`text-white bg-blue-500 p-2 font-bold rounded-xl h-16 text-sm flex items-center justify-center cursor-pointer`}
              >
                {loading ? (
                  <div className="flex items-center">
                    <svg
                      className="animate-spin h-4 w-4 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.962 7.962 0 014 12H0c0 3.351 1.356 6.375 3.514 8.485l1.486-1.194z"
                      ></path>
                    </svg>
                    <span>جاري الإدخال..</span>
                  </div>
                ) : (
                  "ادخال ملف excel"
                )}

                <input
                  type="file"
                  name="excel_file"
                  accept=".xlsx, .xls"
                  onChange={handleClick}
                  className="hidden"
                />
              </label>

            </div>
          </Col>
          <Col className="flex gap-4 items-center justify-between flex-wrap">
            <Col col={10} md={8}>
              <UsernameSearch />
            </Col>
            <Col col={10} md={3}>
              <PopUpAdvancedSearch
                total={data?.data.data.total}
                user={user?.data?.data}
              />

            </Col>
          </Col>
        </Row>
        <Row justify="between" className="bg-white shadow-sm rounded-2xl p-5">
          <Add
            hidBtn={!isAdmin}
            titles={content.Users}
            title={content.User}
            link="/users/add"
          />
          <Table
            isAdmin={isAdmin}
            thead={[
              content.Images,
              content.username,
              content.Email,
              content.Phone,
              content.Gender,
              content.Country,
              content.counterReports,
            ]}
            pageCount={Math.ceil(
              data?.data.data?.total / data?.data.data?.per_page
            )}
            tData={dataAll}
            tbody={[
              "images",
              "username",
              "email",
              "phone_number",
              "gender",
              "country",
              "counter_reports_count",
            ]}
            show="users/details"
            showContent="عرض معلومات المستخدم"
            edit="users"
            funDelete={deleteItem}
            userId="true"
            report="true"
            packages
            isLoading={isLoading}
          />
        </Row>
      </Container>
    </>
  );
}
export default Users;
