import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "sonner";
import { baseUrl } from "../Tools/APIs/useMyAPI";

export default function DownloadFile({
  fileName,
  url,
  title,
  fileType,
  color = "bg-amber-500",
}: {
  fileName: string;
  url: string;
  title: string;
  fileType: string;
  color?: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const urlToSend = baseUrl + `${url}`;

  const handleClick = async () => {
    try {
      setIsLoading(true);

      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Authentication token is missing.");
        setIsLoading(false);
        return;
      }

      const response = await axios.get(urlToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: `application/${fileType}`,
        },
        responseType: "blob",
      });

      setIsLoading(false);
      toast.dismiss("loading");

      const blob = new Blob([response.data], { type: fileType });
      const fileUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(fileUrl); // Fix: Use the correct URL variable
    } catch (error) {
      setIsLoading(false);
      toast.error(`Error exporting file: ${error.message}`);
      toast.dismiss("loading");
    }
  };

  useEffect(() => {
    if (isLoading) toast.loading("يتم التحميل", { id: "loading" });
  }, [isLoading]);

  return (
    <button
      className={`${color} text-white p-2 font-bold rounded-xl h-16 text-sm flex items-center justify-center`}
      onClick={handleClick}
      disabled={isLoading}
    >
      {isLoading ? (
        <>
          <svg
            className="animate-spin h-4 w-4 mr-2 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C6.477 0 2 4.477 2 10h2zm2 5.291A7.962 7.962 0 014 12H0c0 3.351 1.356 6.375 3.514 8.485l1.486-1.194z"
            ></path>
          </svg>
          تحميل...
        </>
      ) : (
        title
      )}
    </button>
  );
}
